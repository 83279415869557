import React from "react";
import axios from "axios";
import { useEffect, useState, useRef, useCallback } from "react";
import "../assets/styles/user.css";
import "../assets/styles/wizard.css";
import Formater from "./Formater";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";

const Tracking = (props) => {
  const bottomRef = useRef(null);
  const data = props.value;
  const listGender = props.genderParam;
  const listNationality = props.nationalityParam;
  const customerData = props.customerData;

  // Development
  // let apiUrl = "http://192.168.1.12:8000/api";
  // let apiUrl = "http://192.168.1.50:8000/api";
  // let apiUrl = "http://127.0.0.1:8000/api";
  // let apiUrl = "http://192.168.135.47:8000/api";
  // let apiUrl = "http://192.168.135.6:8000/api";
  // let apiUrl = "http://192.168.1.9:8000/api";

  // Production
  let apiUrl = "https://maxxsco-api.maxxsgroup.com/api";

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://maxxsco-api.maxxsgroup.com",
      // "Access-Control-Allow-Origin": "*",
    },
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalData, setShowModalData] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function openModalData() {
    setShowModalData(true);
  }

  function closeModalData() {
    setShowModalData(false);
  }

  // --------------- Fetch Data Customer After Input Data ------------------------- //
  const [guestName, setGuestName] = useState("");
  const [guestData, setGuestData] = useState("");

  const fetchDataCustomer = useCallback(async (id) => {
    await axios
      .get(apiUrl + `/customer/tracking/data_customer/${id}`, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.status === "error") {
          console.log(response);
        } else if (response.data.status === "success") {
          setGuestName(response.data.data.customer.full_name);
          setGuestData(response.data.data.customer);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // --------------- End Fetch Data Customer After Input Data ------------------------- //

  const [dataLog, setDataLog] = useState(data.log);

  const fetchLog = async (id) => {
    await axios
      .get(apiUrl + `/customer/tracking/fetch_log/${id}`, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then(function (response) {
        if (response.data.status === "success") {
          setDataLog(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateStatus = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Updating Status ...",
          html: "Please wait ...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        axios
          .get(apiUrl + `/customer/tracking/update/${id}`, axiosConfig)
          .then(function (response) {
            Swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 1500,
            });

            fetchLog(response.data.data.id);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "An Error Occured!",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  // ------------ Section Field Personal -------------------- //
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [originalAddress, setOriginalAddress] = useState("");
  const [domicileAddress, setDomicileAddress] = useState("");
  const [checkCustomerData, setCheckCustomerData] = useState(customerData === null ? false : true);

  const resetPersonalField = () => {
    setFullName("");
    setPhone("");
    setEmail("");
    setGender("");
    setNationality("");
    setOriginalAddress("");
    setDomicileAddress("");
  };
  // ------------ End Section Field Personal -------------------- //

  // ------------- Section check validate email field ----------------- //
  const [emailValidation, setEmailValidation] = useState({ valid: false, message: "" });

  const validateEmail = (value) => {
    //regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value || value.length === 0) {
      return setEmailValidation({ valid: true, message: "Email is required" });
      // return "Email is required";
    }

    if (!regex.test(value)) {
      return setEmailValidation({ valid: true, message: "Invalid email format" });
      // return "Invalid email format";
    }

    return setEmailValidation({ valid: false, message: "" });

    // return null;
  };
  // ------------- End Section check validate email field ----------------- //

  // ------------- Senction Additional Info --------------------- //
  // ------------- End Senction Additional Info --------------------- //
  const [passportNumber, setPassportNumber] = useState("");
  const [passportDateIssued, setPassportDateIssued] = useState("");
  const [passportDateExpired, setPassportDateExpired] = useState("");

  // ------------- Validate Personal Details Form -------------- //
  const checkPersonalTab = () => {
    if (fullName && phone && email && gender && nationality && originalAddress && domicileAddress !== "") {
      return true;
    }

    return false;
  };

  const errorValidatePersonalTab = () => {
    Swal.fire({
      icon: "warning",
      title: "Please fill all fields to continue!",
    });
  };
  // ------------- End Validate Personal Details Form -------------- //
  // ------------- Validate Addtional Form ------------------------- //
  const checkAdditionalTab = () => {
    if (passportNumber && passportDateIssued && passportDateExpired !== "") {
      return true;
    }
    return false;
  };

  const errorValidateAdditionalTab = () => {
    Swal.fire({
      icon: "warning",
      title: "Please fill all fields to continue!",
    });
  };
  // ------------- End Validate Addtional Form ------------------------- //
  // ------------- Last Step Section ---------------------------- //
  const [etcFiles, setEtcFiles] = useState([]);
  const [typeSocial, setTypeSocial] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [job, setJob] = useState("");
  const [company, setCompany] = useState("");
  // ------------- End Last Step Section ---------------------------- //

  const [inputPassportFiles, setInputPassportFiles] = useState([{ passport_files: [] }]);
  const [passportFiles, setPassportFiles] = useState([]);
  const [inputFilesEtc, setInputFilesEtc] = useState([{ etc: [] }]);

  const handlePassportFiles = (e) => {
    setPassportFiles([...e.target.files]);
  };

  const handleFormPassport = (index, event) => {
    let data = [...inputPassportFiles];
    data[index][event.target.name] = event.target.files;
    setInputPassportFiles(data);
  };

  const addFieldPassport = () => {
    let newField = { passport_files: "" };

    setInputPassportFiles([...inputPassportFiles, newField]);

    // console.log(inputPassportFiles.length);
  };

  const removeFieldPassport = (index) => {
    let data = [...inputPassportFiles];

    data.splice(index, 1);
    setInputPassportFiles(data);
  };

  const addFieldEtcFiles = () => {
    let newField = [...inputFilesEtc];

    setInputFilesEtc([...inputFilesEtc, newField]);
  };

  const handleFormEtcFiles = (index, event) => {
    let data = [...inputFilesEtc];
    data[index][event.target.name] = event.target.files[0];
    setInputFilesEtc(data);
  };

  const removeFieldEtcFiles = (index) => {
    let data = [...inputFilesEtc];

    data.splice(index, 1);
    setInputFilesEtc(data);
  };

  const [validation, setValidation] = useState([]);
  const handleComplete = async (e) => {
    e.preventDefault();

    // let filePassport = [...inputPassportFiles];

    const formData = new FormData();

    formData.append("track_master_id", data.id);
    formData.append("full_name", fullName);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("gender", gender);
    formData.append("nationality", nationality);
    formData.append("original_address", originalAddress);
    formData.append("domicile_address", domicileAddress);
    formData.append("passport_number", passportNumber);
    formData.append("passport_date_issued", passportDateIssued);
    formData.append("passport_date_expired", passportDateExpired);
    formData.append("passport_files", passportFiles);
    formData.append("etc_files", etcFiles);
    // formData.append("social_media", typeSocial + " - " + socialMedia);
    formData.append("social_media", typeSocial && socialMedia ? typeSocial + " - " + socialMedia : "");
    formData.append("job", job);
    formData.append("company", company);

    // const formData = {
    //   track_master_id: data.id,
    //   full_name: fullName,
    //   phone: phone,
    //   email: email,
    //   gender: gender,
    //   nationality: nationality,
    //   original_address: originalAddress,
    //   domicile_address: domicileAddress,
    //   passport_number: passportNumber,
    //   passport_date_issued: passportDateIssued,
    //   passport_date_expired: passportDateExpired,
    //   passport_files: passportFiles,
    // };

    // console.log(...formData);

    Swal.fire({
      title: "Saving Data ...",
      html: "Please wait ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(apiUrl + `/customer/tracking/store_customer`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "https://api.maxxsgroup.com",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status === "error") {
          setValidation(response.data.data);
          Swal.fire({
            icon: "error",
            title: response.data.message,
            html: Object.values(response.data.data).map((items) => {
              return items;
            }),
            // timer: 2000,
          });
          // console.log(response);
        } else if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            timer: 2000,
          });

          fetchDataCustomer(response.data.data.track_master_id);
          closeModal();
          resetPersonalField();
          setCheckCustomerData(true);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleBack = (handlePrevious) => {
    return (
      <button className="btn btn-primary" type="button" onClick={handlePrevious}>
        Back
      </button>
    );
  };

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    // bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  return (
    <>
      {checkCustomerData ? (
        <div className="col-sm-12 col-md-8 bg-dark p-3 p-md-4 p-lg-5 text-white" id="resWrapper">
          <div ref={bottomRef} />
          <div data-bs-spy="scroll" data-bs-target="#resWrapper" data-bs-offset="0" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex="0">
            <div className="mb-4">
              {/* <button type="button" className="btn btn-primary" onClick={openModalData}>
              </button> */}
              <h3>
                <i className="bi bi-person-circle"></i> {customerData?.full_name !== undefined ? customerData.full_name : guestName ? guestName : "Guest"}
              </h3>
            </div>
            <div className="row mb-3">
              <div className="col">
                <h5 className="m-0 p-0">Receipt Summary</h5>
              </div>
            </div>
            <div className="row mb-3 border-bottom border-opacity-75">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <p className="small fw-light fst-italic m-0 p-0">Number</p>
                <p className="m-0 p-0">
                  <strong>{data.receipt_number}</strong>
                </p>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
                <p className="small fw-light fst-italic m-0 p-0">Type</p>
                <p className="m-0 p-0">
                  <strong>{data.type}</strong>
                </p>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5">
                <p className="small fw-light fst-italic m-0 p-0">Date</p>
                <p className="m-0 p-0">
                  <strong>{Formater.beautyDate(data.date)}</strong>
                </p>
              </div>
            </div>
            <div className="row mb-3 border-bottom border-opacity-75">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <p className="small fw-light fst-italic m-0 p-0">Customer</p>
                <p className="m-0 p-0">
                  <strong>{customerData?.full_name !== undefined ? customerData.full_name : guestName ? guestName : "Guest"}</strong>
                </p>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-3">
                <p className="small fw-light fst-italic m-0 p-0">Description</p>
                <p className="m-0 p-0">
                  <strong>{data.description}</strong>
                </p>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-5">
                <p className="small fw-light fst-italic m-0 p-0">Status</p>
                <p className="m-0 p-0">
                  {data.status === "new_request" ? (
                    <strong>New Request</strong>
                  ) : data.status === "on_progress" ? (
                    <strong>On Progress</strong>
                  ) : data.status === "sent_by_maxxs" ? (
                    <strong>Sent By Maxx's</strong>
                  ) : data.status === "received_by_customer" ? (
                    <strong>Received By Customer</strong>
                  ) : data.status === "completed_by_system" ? (
                    <strong>Completed By System</strong>
                  ) : data.status === "completed/close" ? (
                    <strong>Completed</strong>
                  ) : data.status === "cancel" ? (
                    <strong>Canceled</strong>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            {data.status === "sent_by_maxxs" ? (
              <div className="mb-3">
                <button className="btn btn-primary" type="button" onClick={() => updateStatus(data.id)}>
                  Click here to Confirm Transaction
                </button>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col">
                <ol>
                  {dataLog.map((data, index) => {
                    return (
                      <li key={index} className="c-timeline__item">
                        <div className="c-timeline__content">
                          <h5 className="c-timeline__title">{data.status === "new_request" ? "New Request" : data.status}</h5>
                          <p className="c-timeline__desc">{data.description}</p>
                        </div>
                        <p className="c-timeline__time text-secondary">{data.status_date === null ? "-" : Formater.beautyDateTime(data.status_date)}</p>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-sm-12 col-md-8 bg-dark p-3 p-md-4 p-lg-5 text-white" id="resWrapper">
          <div ref={bottomRef} />
          <div data-bs-spy="scroll" data-bs-target="#resWrapper" data-bs-offset="0" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex="0">
            <div className="mb-4">
              <h3>
                {/* <i className="bi bi-person-circle"></i> {data["customer"].first_name} {data["customer"].last_name} */}
                <i className="bi bi-person-circle"></i> Guest
              </h3>
            </div>
            <div className="px-4 py-5 my-5 text-center">
              <div className="fs-1">
                <i className="bi bi-info-circle"></i>
              </div>
              <h1 className="display-5 fw-bold text-body-emphasis">Complete Your Personal Data</h1>
              <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">Please fill up your personal data first before continue the process.</p>
                <div className="d-grid gap-2">
                  <button className="btn btn-primary" type="button" onClick={openModal}>
                    <i className="bi bi-plus-circle"></i> Insert Personal Data
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Input Data  */}

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Additional Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-secondary p-2" role="alert">
            <p className="small p-0 m-0">
              <i className="bi bi-exclamation-circle-fill pe-2 p-0 m-0"></i>
              Sign <span className="text-danger">*</span> is a mandatory data
            </p>
          </div>
          <div style={{ marginBottom: "-30px" }}></div>
          <form onSubmit={handleComplete} encType="multipart/form-data">
            <FormWizard
              stepSize="sm"
              // onComplete={handleComplete}
              backButtonTemplate={handleBack}
              nextButtonTemplate={(handleNext) => (
                <button className="btn btn-primary float-end" type="button" onClick={handleNext}>
                  Next
                </button>
              )}
              finishButtonTemplate={(handleComplete) => (
                <button className="btn btn-primary float-end" type="submit" onClick={handleComplete}>
                  Finish
                </button>
              )}
            >
              <FormWizard.TabContent title="Personal details" icon="ti-user">
                <div className="form-floating mb-3">
                  <input type="text" className={`form-control ${validation.full_name ? "is-invalid" : ""}`} id="floatingFullName" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                  <label htmlFor="floatingFullName">
                    <span className="text-danger">*</span> Full Name
                  </label>
                  {validation.full_name && (
                    <div className={`invalid-feedback ${validation.full_name ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.full_name[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <label htmlFor="floatingInput">
                    <span className="text-danger">*</span> Phone
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className={`form-control ${emailValidation.valid || validation.email ? "is-invalid" : ""}`}
                    id="floatingInput"
                    placeholder="name@example.com"
                    onBlur={() => {
                      validateEmail(email);
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingInput">
                    <span className="text-danger">*</span> Email Address
                  </label>
                  {emailValidation.message && (
                    <div className={`invalid-feedback ${emailValidation.message ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {emailValidation.message}
                    </div>
                  )}
                  {validation.email && (
                    <div className={`invalid-feedback ${validation.email ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.email[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.gender ? "is-invalid" : ""}`} id="floatingGender" aria-label="Floating label select example" value={gender} onChange={(e) => setGender(e.target.value)}>
                    <option>-- Select Gender -- </option>
                    {listGender.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingGender">
                    <span className="text-danger">*</span> Gender
                  </label>
                  {validation.gender && (
                    <div className={`invalid-feedback ${validation.gender ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.gender[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <select className={`form-select ${validation.nationality ? "is-invalid" : ""}`} id="floatingNationality" aria-label="Floating label select example" value={nationality} onChange={(e) => setNationality(e.target.value)}>
                    <option>-- Select Nationality --</option>
                    {listNationality.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingNationality">
                    <span className="text-danger">*</span> Nationality
                  </label>
                  {validation.nationality && (
                    <div className={`invalid-feedback ${validation.nationality ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.nationality[0]}
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <textarea className="form-control" placeholder="Leave a comment here" id="floatingOriginalAddress" style={{ height: "100px" }} value={originalAddress} onChange={(e) => setOriginalAddress(e.target.value)}></textarea>
                  <label htmlFor="floatingOriginalAddress">
                    <span className="text-danger">*</span> Original Address
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <textarea className="form-control" placeholder="Leave a comment here" id="floatingDomicileAddress" style={{ height: "100px" }} value={domicileAddress} onChange={(e) => setDomicileAddress(e.target.value)}></textarea>
                  <label htmlFor="floatingDomicileAddress">
                    <span className="text-danger">*</span> Address in Indonesia
                  </label>
                </div>
              </FormWizard.TabContent>
              <FormWizard.TabContent title="Additional Info" icon="ti-settings" isValid={checkPersonalTab()} validationError={errorValidatePersonalTab}>
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="floatingPassportNumber" placeholder="Passport Number" value={passportNumber} onChange={(e) => setPassportNumber(e.target.value)} />
                  <label htmlFor="floatingPassportNumber">
                    <span className="text-danger">*</span> Passport Number
                  </label>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <div className="form-floating">
                      <input type="date" className="form-control" id="floatingPassportDate" placeholder="Passport Issued Date" value={passportDateIssued} onChange={(e) => setPassportDateIssued(e.target.value)} />
                      <label htmlFor="floatingPassportDate">
                        <span className="text-danger">*</span> Passport Issued Date
                      </label>
                    </div>
                  </div>
                  <div className="col mb-3">
                    <div className="form-floating">
                      <input type="date" className="form-control" id="floatingPassportExp" placeholder="Passport Expired Date" value={passportDateExpired} onChange={(e) => setPassportDateExpired(e.target.value)} />
                      <label htmlFor="floatingPassportExp">
                        <span className="text-danger">*</span> Passport Expired Date
                      </label>
                    </div>
                  </div>
                </div>

                <p htmlFor="formFile" className="mb-3 text-start">
                  <span className="text-danger">*</span> File Passport
                </p>

                <input className="form-control mb-3" name="passport_files" type="file" id="formFile" onChange={(e) => setPassportFiles(e.target.files[0])} />

                {/* {inputPassportFiles.map((input, index) => {
                  return (
                    <div key={index}>
                      <div className="row">
                        <div className="col-md-10">
                          <input className="form-control mb-3" name="passport_files" type="file" id="formFile" onChange={(event) => handleFormPassport(index, event)} />
                        </div>
                        <div className="col">
                          <div className="d-grid gap-2 mb-3">
                            <button className="btn btn-primary" type="button" onClick={removeFieldPassport}>
                              <i className="bi bi-trash-fill"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })} */}
                {/* <div className="d-grid gap-2 mb-3">
                  <button className="btn btn-primary" type="button" onClick={addFieldPassport}>
                    <i className="bi bi-plus-circle"></i> Add More Passports
                  </button>
                </div> */}
              </FormWizard.TabContent>
              <FormWizard.TabContent title="Last step" icon="ti-check" isValid={checkAdditionalTab()} validationError={errorValidateAdditionalTab}>
                <div className="mb-3">
                  <p className="form-label text-start">
                    <span className="text-danger">*</span> Other File (Lastest Visa/Kitas/etc)
                  </p>
                  <input className={`form-control mb-3 ${validation.etc_files ? "is-invalid" : ""}`} type="file" id="formFile" name="etc_files" onChange={(e) => setEtcFiles(e.target.files[0])} />
                  {validation.etc_files && (
                    <div className={`invalid-feedback ${validation.etc_files ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.etc_files[0]}
                    </div>
                  )}
                  {/* {inputFilesEtc.map((input, index) => {
                    return (
                      <div key={index}>
                        <div className="row">
                          <div className="col-md-10">
                          </div>
                          <div className="col">
                            <div className="d-grid gap-2 mb-3">
                              <button className="btn btn-primary" type="button" onClick={removeFieldEtcFiles}>
                                <i className="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })} */}
                  {/* <div className="d-grid gap-2 mb-3">
                    <button className="btn btn-primary" type="button" onClick={addFieldEtcFiles}>
                      <i className="bi bi-plus-circle"></i> Add More Files
                    </button>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-floating">
                      <select className={`form-select ${validation.social_media ? "is-invalid" : ""}`} id="floatingSelect" value={typeSocial} onChange={(e) => setTypeSocial(e.target.value)} aria-label="Floating label select example">
                        <option>-- Select Social Media --</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Instagram">instagram</option>
                        <option value="Twitter">Twitter</option>
                      </select>
                      <label htmlFor="floatingSelect">
                        <span className="text-danger">*</span> Type Social Media
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input type="text" className={`form-control ${validation.social_media ? "is-invalid" : ""}`} value={socialMedia} onChange={(e) => setSocialMedia(e.target.value)} id="floatingFullName" placeholder="Full Name" />
                      <label htmlFor="floatingFullName">
                        <span className="text-danger">*</span> Social Media
                      </label>
                    </div>
                  </div>
                </div>
                {validation.social_media && (
                  <div className={`invalid-feedback ${validation.social_media ? "d-block mb-3" : "d-none"}`}>
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {validation.social_media[0]}
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input type="text" className={`form-control ${validation.job ? "is-invalid" : ""}`} value={job} onChange={(e) => setJob(e.target.value)} id="floatingJob" placeholder="Full Name" />
                      <label htmlFor="floatingJob">
                        <span className="text-danger">*</span> Job
                      </label>
                      {validation.job && (
                        <div className={`invalid-feedback ${validation.job ? "d-block mb-3" : "d-none"}`}>
                          <i className="bi bi-exclamation-circle me-2"></i>
                          {validation.job[0]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input type="text" className={`form-control ${validation.company ? "is-invalid" : ""}`} value={company} onChange={(e) => setCompany(e.target.value)} id="floatingCompany" placeholder="Full Name" />
                      <label htmlFor="floatingCompany">
                        <span className="text-danger">*</span> Company
                      </label>
                      {validation.company && (
                        <div className={`invalid-feedback ${validation.company ? "d-block mb-3" : "d-none"}`}>
                          <i className="bi bi-exclamation-circle me-2"></i>
                          {validation.company[0]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </FormWizard.TabContent>
            </FormWizard>
          </form>

          {/* add style */}
          <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>
        </Modal.Body>
        {/* <Modal.Footer>
          <button className="btn btn-danger" onClick={closeModal}>
            Close
          </button>
          <button className="btn btn-primary" onClick={closeModal}>
            Save Changes
          </button>
        </Modal.Footer> */}
      </Modal>

      {/* Modal Show Data */}
      <Modal show={showModalData} onHide={closeModalData}>
        <Modal.Header closeButton>
          <Modal.Title>Additional Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-6 mb-3">
                <p className="fs-6 text-secondary">Full Name</p>
                <p className="fs-6">{customerData?.full_name !== undefined ? customerData.full_name : guestData.full_name ? guestData.full_name : "Guest"}</p>
              </div>
              <div className="col-6">
                <p className="fs-6 text-secondary">Phone</p>
                <p className="fs-6">{customerData?.phone !== undefined ? customerData.phone : guestData.phone ? guestData.phone : "Guest"}</p>
              </div>
              <div className="col-6 mb-3">
                <p className="fs-6 text-secondary">Email</p>
                <p className="fs-6">{customerData?.email !== undefined ? customerData.email : guestData.email ? guestData.email : "Guest"}</p>
              </div>
              <div className="col-6">
                <p className="fs-6 text-secondary">Gender</p>
                <p className="fs-6">{customerData?.param_gender?.name !== undefined ? customerData.param_gender.name : guestData?.param_gender?.name ? guestData.param_gender.name : "Guest"}</p>
              </div>
              <div className="col-6 mb-3">
                <p className="fs-6 text-secondary">Nationality</p>
                <p className="fs-6">{customerData?.param_nationality?.name !== undefined ? customerData.param_nationality.name : guestData.param_nationality ? guestData.param_nationality.name : "Guest"}</p>
              </div>
              <div className="col-6">
                <p className="fs-6 text-secondary">Original Address</p>
                <p className="fs-6">{customerData?.original_address !== undefined ? customerData.original_address : guestData.original_address ? guestData.original_address : "Guest"}</p>
              </div>
              <div className="col-6 mb-3">
                <p className="fs-6 text-secondary">Address in Indonesia</p>
                <p className="fs-6">{customerData?.domicile_address !== undefined ? customerData.domicile_address : guestData.domicile_address ? guestData.domicile_address : "Guest"}</p>
              </div>
              <div className="col-6">
                <p className="fs-6 text-secondary">Social Media</p>
                <p className="fs-6">{customerData?.social_media !== undefined ? customerData.social_media : guestData.social_media ? guestData.social_media : "Guest"}</p>
              </div>
              <div className="col-6 mb-3">
                <p className="fs-6 text-secondary">Job</p>
                <p className="fs-6">{customerData?.job !== undefined ? customerData.job : guestData.job ? guestData.job : "Guest"}</p>
              </div>
              <div className="col-6">
                <p className="fs-6 text-secondary">Company</p>
                <p className="fs-6">{customerData?.company !== undefined ? customerData.company : guestData.company ? guestData.company : "Guest"}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Tracking;

import React from "react";
import "../assets/styles/user.css";

export const DefaultIndex = () => {
  return (
    <div className="col-sm-12 col-md-8 bg-dark p-5 text-white" id="resWrapper">
      <div data-bs-spy="scroll" data-bs-target="#resWrapper" data-bs-offset="0" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex="0">
        <h2 className="p-0 m-0 text-nowrap">About Us</h2>
        <p className="fw-lighter">We are proud to be a one stop service company for International Business in Indonesia.</p>
        <p className="fw-lighter">
          We provide professional support for <span className="h6 px-1">Legal Documents</span>, <span className="h6 px-1">Hospitality</span>, <span className="h6 px-1">Property</span>, <span className="h6 px-1">Export / Import</span> and{" "}
          <span className="h6 px-1">General Trading</span>.
        </p>
        <p className="fw-lighter">Our experienced staff members come from various regions in Indonesia and overseas, integrating their knowledge and expertise to assist your needs and make the process as simple as possible for you.</p>
        <hr className="my-4" />
        <h2 className="p-0 m-0 text-nowrap text-center">Our Offices</h2>
        <div className="p-0 m-0 row">
          <div className="col-sm-12 col-md-2 col-lg-3"></div>
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="p-2 text-center">
              <p className=" m-0 p-0 lead">Headquarter</p>
              <p className=" m-0 p-0 small fw-lighter">
                Plaza 89 Building, 4<sup>th</sup> Floor, Suite 405
                <br />
                HR. Rasuna Said Street Kav. X-7 No. 6, RT.6/RW.7, Kuningan, Karet Kuningan, Setiabudi, South Jakarta, DKI Jakarta 12940
              </p>
              {/* <p className="m-0 p-0 small fw-lighter">
                Phone:{" "}
                <a
                  href="tel:622150813027"
                  className="text-light text-decoration-none"
                  rel="noreferrer"
                >
                  +62 21 5081 3027
                </a>
              </p> */}
              <p className="m-0 p-0 small fw-lighter">
                Mobile:{" "}
                <a href="https://wa.me/6281228229384" className="text-light text-decoration-none" rel="noreferrer">
                  +62 812 2822 9384
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-2 col-lg-3"></div>
        </div>
        <div className="p-0 m-0 row">
          <div className="col-sm-12 col-md-6 col-lg-4 bg-warning bg-opacity-10">
            <div className="p-2">
              <p className="m-0 p-0 lead">Bali (Ubud)</p>
              <p className="m-0 p-0 small fw-lighter">
                <sup>
                  <em>Operate by Bali Expat Center</em>
                </sup>
              </p>
              <p className="m-0 p-0 small fw-lighter">Jl. Raya Sanggingan No.36 Kedewatan, Kecamatan Ubud, Kabupaten Gianyar, Bali, 80571</p>
              <p className="m-0 p-0 small fw-lighter">
                Mobile:{" "}
                <a href="https://wa.me/6283831043792" className="text-light text-decoration-none" rel="noreferrer">
                  +62 838 3104 3792
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 bg-danger bg-opacity-10">
            <div className="p-2">
              <p className="m-0 p-0 lead">Bali (Kerobokan)</p>
              <p className="m-0 p-0 small fw-lighter">Jl. Raya Semer No.12, Kerobokan Kelod, Kec. Kuta Utara, Kabupaten Badung, Bali, 80361</p>
              <p className="m-0 p-0 small fw-lighter">
                Mobile:{" "}
                <a href="https://wa.me/6281338376660" className="text-light text-decoration-none" rel="noreferrer">
                  +62 813 3837 6660
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 bg-secondary bg-opacity-10">
            <div className="p-2">
              <p className=" m-0 p-0 lead">Lombok</p>
              <p className=" m-0 p-0 small fw-lighter">Jl. Raya Senggigi, Pasar Seni KM 8 Senggigi Art Market Arcade KT II No. 2 Batulayar, Senggigi, Batu Layar, Nusa Tenggara Barat</p>
              <p className="m-0 p-0 small fw-lighter">
                Mobile:{" "}
                <a href="https://wa.me/6281353508970" className="text-light text-decoration-none" rel="noreferrer">
                  +62 813 5350 8970
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="p-0 m-0 row">
          <div className="col-sm-12 col-md-6 col-lg-4 bg-success bg-opacity-10">
            <div className="p-2">
              <p className=" m-0 p-0 lead">Jakarta</p>
              <p className=" m-0 p-0 small fw-lighter">
                Office 89 Building, 4<sup>th</sup> Floor, Suite 405
                <br />
                HR. Rasuna Said Street Kav. X-7 No. 6, RT.6/RW.7, Kuningan, Karet Kuningan, Setiabudi, South Jakarta, DKI Jakarta 12940
              </p>
              {/* <p className="m-0 p-0 small fw-lighter">
                Phone:{" "}
                <a
                  href="tel:622150813027"
                  className="text-light text-decoration-none"
                  rel="noreferrer"
                >
                  +62 21 5081 3027
                </a>
              </p> */}
              <p className="m-0 p-0 small fw-lighter">
                Mobile:{" "}
                <a href="https://wa.me/6281228229384" className="text-light text-decoration-none" rel="noreferrer">
                  +62 812 2822 9384
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 bg-info bg-opacity-10">
            <div className="p-2">
              <p className=" m-0 p-0 lead">Surabaya</p>
              <p className=" m-0 p-0 small fw-lighter">Hotel Bumi Surabaya, Jl. Jend. Basuki Rahmat No.106-128, Embong Kaliasin, Surabaya 60271</p>
              <p className="m-0 p-0 small fw-lighter">
                Phone:{" "}
                <a href="tel:62315326353" className="text-light text-decoration-none" rel="noreferrer">
                  +62 31 5326 353
                </a>
              </p>
              <p className="m-0 p-0 small fw-lighter">
                Mobile:{" "}
                <a href="https://wa.me/6285333351729" className="text-light text-decoration-none" rel="noreferrer">
                  +62 853 3335 1729
                </a>
              </p>
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-6 col-lg-4 bg-primary bg-opacity-10">
            <div className="p-2">
              <p className=" m-0 p-0 lead">Batam</p>
              <p className=" m-0 p-0 small fw-lighter">
                Gedung Graha Pena, 1<sup>st</sup> Floor
                <br />
                Jl. Raya Batam Center, Kota Batam, Batam 29461
              </p>
              <p className="m-0 p-0 small fw-lighter">
                Mobile:{" "}
                <a
                  href="https://wa.me/6281228229384"
                  className="text-light text-decoration-none"
                  rel="noreferrer"
                >
                  +62 812 2822 9384
                </a>
              </p>
            </div>
          </div> */}
          <div className="col-sm-12 col-md-6 col-lg-4 bg-primary bg-opacity-10">
            <div className="p-2">
              <p className="m-0 p-0 lead">Coming Soon</p>
              <ul className="small fw-lighter">
                <li>Medan</li>
                <li>Batam</li>
                <li>Yogyakarta</li>
              </ul>
              <p className=" m-0 p-0 small fw-lighter"></p>
            </div>
          </div>
        </div>
        <hr className="my-4" />
        <div className="d-flex justify-content-end">
          <ul className="nav nav-fill">
            <li className="nav-item px-2">
              <p className="fw-lighter small">
                <em>Follow Our Social Media</em>
              </p>
            </li>
            <li className="nav-item px-2">
              <a href="https://www.instagram.com/maxxsgroupinternasional/" title="Follow us on Instagram" target="_blank" className="text-muted" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                </svg>
              </a>
            </li>
            <li className="nav-item px-2">
              <a href="https://www.facebook.com/maxxsgroup/" title="Follow us on Facebook" target="_blank" className="text-muted" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </a>
            </li>
            <li className="nav-item px-2">
              <a href="https://www.linkedin.com/company/pt-maxx-s-group-internasional/" title="Follow us on Linkedin" target="_blank" className="text-muted" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                </svg>
              </a>
            </li>
            <li className="nav-item px-2">
              <a href="https://www.youtube.com/channel/UCsSfZFGprOGwHrsaT61jALA" title="Subscribe on our youtube channel" target="_blank" className="text-muted" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                </svg>
              </a>
            </li>
            <li className="nav-item px-2">
              <a href="https://www.tiktok.com/@maxxsgroupinternational" title="Follow us on Tiktok" target="_blank" className="text-muted" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

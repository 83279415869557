function getFullMonthName(params) {
  return params === 1
    ? "January"
    : params === 2
    ? "February"
    : params === 3
    ? "March"
    : params === 4
    ? "April"
    : params === 5
    ? "May"
    : params === 6
    ? "June"
    : params === 7
    ? "July"
    : params === 8
    ? "August"
    : params === 9
    ? "September"
    : params === 10
    ? "October"
    : params === 11
    ? "November"
    : "December";
}

function _beautyDate(pDate) {
  var _date = new Date(pDate);
  var _year = _date.getFullYear();
  var _month = _date.getMonth() + 1; //January is 0!
  var _day = _date.getDate();

  if (_day < 10) _day = "0" + _day;
  return getFullMonthName(_month) + " " + _day + ", " + _year;
}

function _beautyDateTime(pDate) {
  var _date = new Date(pDate);
  var _year = _date.getFullYear();
  var _month = _date.getMonth() + 1; //January is 0!
  var _day = _date.getDate();
  var _hour = _date.getHours();
  var _minute = _date.getMinutes();
  var _second = _date.getSeconds();
  // var _ampm = _hour <= 12 ? "AM" : "PM";

  if (_day < 10) _day = "0" + _day;
  if (_hour < 10) _hour = "0" + _hour;
  if (_minute < 10) _minute = "0" + _minute;
  if (_second < 10) _second = "0" + _second;

  return getFullMonthName(_month) + " " + _day + ", " + _year + " " + _hour + ":" + _minute + ":" + _second;
}

// function _getConfig() {
//   if (localStorage.getItem("maxxsCo") == null) {
//     localStorage.setItem("maxxsCo", "maxxsCo Advance Tracking System");
//   }
//   return localStorage.getItem("maxxsCo");
// }

class Formater {
  static beautyDate(pDate) {
    return _beautyDate(pDate);
  }

  static beautyDateTime(pDate) {
    return _beautyDateTime(pDate);
  }

  // static getConfig() {
  //   return _getConfig();
  // }
}
export default Formater;

import React, { useState } from "react";
import axios from "axios";
import logo from "../assets/images/logo.png";
import { DefaultIndex } from "./DefaultIndex";
import Tracking from "./Track";
import PackageJson from "../../package.json";
import "../assets/styles/user.css";
// import Formater from "./Formater";

export default function IndexTrack() {
  // Development
  // let apiUrl = "http://192.168.1.12:8000/api";
  // let apiUrl = "http://192.168.1.50:8000/api";
  // let apiUrl = "http://localhost:8000/api";
  // let apiUrl = "http://192.168.135.47:8000/api";
  // let apiUrl = "http://192.168.135.6:8000/api";
  // let apiUrl = "http://192.168.1.9:8000/api";

  // Production
  let apiUrl = "https://maxxsco-api.maxxsgroup.com/api";

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://maxxsco-api.maxxsgroup.com",
      // "Access-Control-Allow-Origin": "*",
    },
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const [receipt, setReceipt] = useState("");
  // const [password, setPassword] = useState("");
  const [track, setTrack] = useState("");
  const [dataCustomers, setDataCustomers] = useState("");
  const [validation, setValidation] = useState([]);

  // const x = Formater.getConfig();
  // console.log(x);

  // ---------------- Fetch Parameters ---------------------  //
  const [listGender, setListGender] = useState([]);
  const [listNationality, setListNationality] = useState([]);
  // ---------------- End Fetch Parameters ---------------------  //

  async function fetchData(e) {
    e.preventDefault();

    setTrack("");

    const formData = new FormData();
    formData.append("receipt_number", receipt);
    // formData.append("password", password);

    setValidation("[{errMessage: null}, {code: null}, {receipt_number: null}]");
    setIsLoaded(true);

    await axios
      .post(apiUrl + "/customer/tracking/receipt", formData, axiosConfig)
      .then(function (response) {
        // console.log(response);
        if (response.data.status === "error") {
          setValidation(response.data.data);
          setIsLoaded(false);
        } else if (response.data.status === "success") {
          let data = response.data.data;

          setTrack(data.master);
          setListGender(data.gender);
          setListNationality(data.nationality);
          setDataCustomers(data.master.customer);
          setIsLoaded(false);
        }
      })
      .catch((error) => {
        // console.log(error);
        setValidation(error);
        setIsLoaded(false);
      });
  }

  function fetchReset() {
    setValidation("[{errMessage: null}, {code: null}, {receipt_number: null}]");
    document.getElementById("fData").reset();
    setIsLoaded(false);
    setTrack("");
  }

  return (
    <>
      <div className="container-fluid main-wrapper">
        <div className="row">
          <div className="col-sm-12 col-md-4 px-3" id="left-wrapper">
            <div className="d-flex flex-column justify-content-center align-items-center align-self-center py-5 px-3">
              <div className="p-2 mb-5">
                <img src={logo} alt="Maxx's Group Logo" className="img-fluid" style={{ maxWidth: "250px" }} />
              </div>
              <div className="p-2 mb-3">
                <p className="lead mb-0 text-center">
                  <strong>Maxx's Co Tracking</strong>
                </p>
                <p className="small mb-0 text-center">Input transaction receipt and it's password to check your latest progress.</p>
              </div>
              {validation.errMessage && (
                <div className={`alert alert-danger alert-dismissible fade show ${validation.errMessage ? "d-block" : "d-none"}`} role="alert">
                  <i className="bi bi-exclamation-circle"></i>&nbsp;
                  {validation.errMessage}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
              )}
              {validation.code && (
                <div className={`alert alert-danger alert-dismissible fade show ${validation.code ? "d-block" : "d-none"}`} role="alert">
                  <i className="bi bi-exclamation-circle"></i>&nbsp;
                  {validation.message}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
              )}
              {validation.receipt_number && (
                <div className={`alert alert-danger alert-dismissible fade show ${validation.receipt_number ? "d-block" : "d-none"}`} role="alert">
                  <i className="bi bi-exclamation-circle"></i>&nbsp;
                  {validation.receipt_number}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
              )}
              <form className="py-2 px-3" onSubmit={fetchData} id="fData">
                <div className="form-floating mb-3">
                  <input type="text" className={`form-control ${validation.receipt_number ? "is-invalid" : ""}`} onChange={(e) => setReceipt(e.target.value)} id="txtreceipt" placeholder="Receipt" required />
                  <label htmlFor="txtreceipt">Receipt Number</label>
                </div>
                {/* <div className="form-floating mb-3">
                  <input type="password" className={`form-control ${validation.password ? "is-invalid" : ""}`} onChange={(e) => setPassword(e.target.value)} id="txtpassword" placeholder="Password" required />
                  <label htmlFor="txtpassword">Password</label>
                  {validation.password && (
                    <div className={`invalid-feedback ${validation.password ? "d-block mb-3" : "d-none"}`}>
                      <i className="bi bi-exclamation-circle me-2"></i>
                      {validation.password[0]}
                    </div>
                  )}
                </div> */}
                <div className="text-end d-grid gap-2">
                  <button type="submit" className="btn btn-primary">
                    {!isLoaded ? (
                      <>
                        <i className="bi bi-search"></i> Search
                      </>
                    ) : (
                      <>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Search
                      </>
                    )}
                  </button>
                </div>
                <div className="d-grid gap-2">
                  <button type="button" className="btn btn-danger mt-3" onClick={fetchReset}>
                    <i className="bi bi-arrow-clockwise"></i> Reset
                  </button>
                </div>
              </form>
              <div className="py-5 text-center">
                <p className="small mb-0">&copy; 2022 - PT Maxxs Group Internasional</p>
                <p className="small mb-0">All Rights Reserved</p>
                <p className="small mb-0 fst-italic text-muted">version {PackageJson.version}</p>
              </div>
            </div>
          </div>
          {track === "" ? <DefaultIndex /> : <Tracking value={track} genderParam={listGender} nationalityParam={listNationality} customerData={dataCustomers} />}
          <div className="sticky-bottom" style={{ marginTop: "-110px" }}>
            <div className="float-end p-2">
              <span className="badge text-bg-light">Contact Us</span>
              <a className="btn btn-lg btn-primary m-3 rounded-circle" href="#" role="button">
                <i className="bi bi-whatsapp fs-3"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
